import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import {getUserToken,getUserID} from '../store/utility'
import axios from 'axios';
import { Menu } from 'antd';
class HelpApplication extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
        staff : false,
        token : "",
        subfolder_link : "",
        menu : "",
        connected_user_id : -1,
        source_link : "helpfiles/app_general.md",
    }
    
    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
        }
    }

    componentDidMount(){
        this.state.connected_user_id = getUserID();
        this.setState({token : getUserToken()});
        this.props.sendTracking(-1,"Web,Help,Application,view");


        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("menu")
        if (type !== undefined && type !== "" && type !== null){
            this.setState({source_link:"helpfiles/"+type+".md"});
        }
        else{
            this.setState({source_link:"helpfiles/app_general.md"});
        }
    }

    handleClick = e => {
        this.setState({
            source_link: e.key,
        });
        switch(e.key){
            case "helpfiles/app_general.md":
                this.setState({subfolder_link:"app_general"});
                break;
            case "helpfiles/dataset.md":
                this.setState({subfolder_link:"app_datasets"});
                break;
            case "helpfiles/info.md":
                this.setState({subfolder_link:"app_infos"});
                break;
            case "helpfiles/objects.md":
                this.setState({subfolder_link:"app_objects"});
                break;   
            case "helpfiles/groups.md":
                this.setState({subfolder_link:"app_groups"});
                break;   
            case "helpfiles/genetic.md":
                this.setState({subfolder_link:"app_genetic"});
                break;   
            case "helpfiles/movie.md":
                this.setState({subfolder_link:"app_movie"});
                break;  
            case "helpfiles/curations.md":
                this.setState({subfolder_link:"app_curations"});
                break; 
        }
      };

    render(){
        return(
			<div>
            <div style={{margin:'auto'}}>
            <h1 className='main-title' style={{marginLeft:'33%'}}> MorphoNet Application help </h1>
                <br></br><br></br>
                <div style={{width:'100%'}}>
                <Menu onClick={this.handleClick} style={{display:'flex',justifyContent: 'center'}} defaultSelectedKeys={this.state.source_link} mode="horizontal">
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/app_general.md">
                   <span className='new-content-text'> General </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/dataset.md">
                   <span className='new-content-text'> Dataset </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/info.md">
                <span className='new-content-text'> Properties </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/objects.md">
                <span className='new-content-text'> Objects </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/groups.md">
                <span className='new-content-text'> Groups </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/genetic.md">
                <span className='new-content-text'> Genetic </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/movie.md">
                <span className='new-content-text'> Movie </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/curations.md">
                <span className='new-content-text'> Curations </span>
                </Menu.Item>
                </Menu>
                </div>
                <br></br>
                {
                    this.state.source_link !== "" &&
                    <MorphoMDEditor subfolderName={this.state.subfolder_link === undefined || this.state.subfolder_link === "" ? "app_general" :this.state.subfolder_link} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpApplication;