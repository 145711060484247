import React from 'react';
import {Avatar, List, Tooltip,Col, Row,Slider} from 'antd';
import { TwitterTimelineEmbed} from 'react-twitter-embed';
import '../App.css';
import {NavLink, withRouter} from 'react-router-dom';
import {encryptString,getUserToken,getUserID,isOnStandalone} from '../store/utility';
import axios from 'axios';

import {
    BrowserView,
    MobileView,
    isIOS,
    isTablet,
  } from "react-device-detect";
import * as actions from "../store/actions/auth";
import {SetRedirectedUrl} from "../store/reducers/userSlice";
import {SearchByName} from "../store/reducers/datasetSlice";
import {connect} from "react-redux";
  

// Safari 3.0+ "[object HTMLElementConstructor]" 


//welcome page (index)
class WelcomeReworked extends React.Component {
    constructor(props){
        super(props);

        this.datasetlist = React.createRef();
        this.twitterdiv = React.createRef();
        this.markerRef = React.createRef();
    }

    getUserSettingsValue(p_setting)
    {
        let value = -1;
        if (this.state.user_settings !== undefined){
          this.state.user_settings.map(sett => {
          if (sett.setting === p_setting){
            value = sett.value;
          }
        });
      }
        return value;
    }

    componentDidMount(){
        this.state.connected_user_id =  getUserID();
        this.state.token = getUserToken();
        this.setState({unity_loaded:this.props.unityLoaded});
        window.addEventListener('disconnect',() => {this.componentDidMount();})
        //window.addEventListener('load', this.handleLoad);

        const u_id = getUserID() ? getUserID() : 0;
        if (JSON.parse(u_id) !== 0){
            axios.get(`/api/people/${JSON.parse(u_id)}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({user:res.data});
        });    

       
    }
        //create favourite list instead of user sets list
        if (this.state.token !== "" && this.state.token !== null){
            axios.get('/api/userfavsets/',{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    sets: res.data.slice(0,this.state.max_user_sets_count),
                    sets_updated : true,
                });
            });
        }
            axios.get('/api/publicgifdatasets/')
            .then(res => {
                this.setState({
                    public_sets: res.data.slice(0,this.state.max_sets_count),
                    public_sets_updated : true,
                });
            });
        

        if (this.state.token !== "" && this.state.token !== null){
            axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    user_settings: res.data,
                });
            });
        }

    }

    componentDidUpdate(prevProps,prevState){
        if (this.props.unityLoaded !== prevProps.unityLoaded){
            this.setState({unity_loaded:this.props.unityLoaded});
          }

          if (this.props.tutorialReady !== prevProps.tutorialReady && this.props.tutorialReady === true){
            //this.props.displayTutorial(`Welcome to MorphoNet`,`${window.innerWidth/3}px`,`${this.datasetlist.current.offsetTop}px`,`${this.datasetlist.current.offsetRight}px`,'welcome-tutorial',"false");
            //console.log(this.datasetlist.current);
           // this.props.displayTutorial(`This is the dataset list component \n with a carriage return`,`${this.datasetlist.current.offsetLeft}px`,`${this.datasetlist.current.offsetTop}px`,`${this.datasetlist.current.offsetRight}px`,'welcome-tutorial','down');
           // this.props.displayTutorial(`This is the twitter feedback \n with a carriage return2`,`${this.twitterdiv.current.offsetLeft}px`,`${this.twitterdiv.current.offsetTop}px`,`${this.twitterdiv.current.offsetRight}px`,'welcome-tutorial');
          }
    }

    componentWillUnmount() { 
        window.removeEventListener('load', this.handleLoad)  
      }
      
    state = {
        max_sets_count : 9,
        max_user_sets_count : 100,
        connected_user_id : -1,
        token : "",
        user: {},
        sets : [],
        public_sets : [],
        public_sets_updated : false,
        sets_updated : false,
        user_settings : [],
        unity_loaded : false,
    }

    handleLoad = () => {
        if (this.state.token === null || this.state.token === ""){
            axios.get('/api/storelogin/',{params:{user_id:0}});
          }
    }
       
    render(){
        return (
            <div>
            <MobileView>
                {
                    isTablet?
                    <div>
<div>
            <span ref={this.markerRef}style={{position:'absolute',top:'30%',left:'50%'}}></span>
            <div style={{display:'table-row',height:'15vh',maxHeight:'100px',width:'100%',position:'relative'}}>
                <div style={{position:'relative',margin:'auto',marginLeft:'11%',minWidth:'960px',marginTop:'5%'}}>
                    <br></br>
                    <h1 className='main-title'> MorphoNet </h1>
                    <br></br>
					<h1 style={{fontSize:'27px',fontWeight:"bold",fontFamily:'Arial'}}> A novel concept of web-based morphodynamic browser. </h1>
                    <br></br>
                    <p className='new-content-text'> MorphoNet is an <b>interactive anatomical browser for 3D, 3D+t segmented datasets</b>. It provides a  <b>comprehensive</b> palette of <b>interaction tools</b> to explore the structure, dynamics and variability of <b>biological shapes, from fossils to developing embryos</b>. Some datasets are also linked to <b>single-cell gene expression data</b>. MorphoNet handles both natural or simulated <b>morphological data</b> and fills a gap which has until now limited the <b>quantitative understanding of morphodynamics and its genetic underpinnings</b> by contributing to the creation of morphological atlases. </p>
                    <br></br>
                </div>
            </div>
            <div style={{display:'table-row',height:'50vh',margin:'auto',width:'100%'}}>

                <div ref={this.datasetlist} style={{marginTop:'2%',width:'80%',float:'left',marginLeft:'11%',marginRight:'10px'}}>
                    <h1 style={{fontSize:'32px'}} className='sub-titles-text'> {this.state.token === "" || this.state.token === null ?"Public datasets examples":"Your favourite sets"}</h1>
                    <NavLink exact to={{pathname:`/dataset`}}><span style={{float:'right',fontSize:'17px',color:'#44D4FF',marginTop:'1%'}}> View all datasets</span></NavLink>
                    {   this.state.sets.length > 0 ?
                        <List
                        itemLayout="horizontal"
                        dataSource={this.state.sets}
                        style={{outline:' 0.5px solid #44D4FF',outlineOffset:'2px',paddingLeft:'10px',minWidth:'600px'}}
                        renderItem={item => (
                        <div>
                        {
                            <List.Item >
                            
                            {
                                this.state.token === null || this.state.token === "" ?
                            <Tooltip placement="bottom" title={<span>{"Load this in the 3D viewer"}</span>}>
                                <img onClick={() => { 
                                    this.props.sendTracking(item.id,"Ipad,Index,Dataset,visualize");this.props.openUnityFromAway();this.props.unityLoadFunc(item);}} alt="dataset gif" width='50' src={"GIF/DATASET_"+item.id+"-50.gif"} style={{cursor:this.state.unity_loaded?'pointer':'',mixBlendMode:'multiply'}}></img>
                                </Tooltip>
                                :
                                <Tooltip placement="bottom" title={<span>Load this dataset to visualizer</span>}><img onClick={() => {this.props.sendTracking(item.id,"Ipad,Index,Dataset,visualize");this.props.openUnityFromAway();this.props.unityLoadFunc(item);}}alt="Load from Unity" width={30} style={{cursor:'pointer',marginLeft:'20px',marginRight:'5px'}} width={30} src={"/images/refonte/load.png"}></img></Tooltip>
                            }

                            <List.Item.Meta
                             title={
                                <Tooltip placement="bottom" title={<span>See dataset details</span>}><NavLink exact to={{pathname:`/dataset`,preChoosenSet:item.id}}><span style={{paddingLeft:'5px'}} onClick={() => {   
                                    this.props.sendTracking(item.id,"Web,Index,Dataset,click");}} className='new-content-text'>{item.name}</span></NavLink></Tooltip>
                          }
                            />
                        </List.Item>
                        }

                        </div>
                        )}
                    />
                        :
                        <div>
                        <br></br>
                        <span style={{fontSize:'23px'}} > Your favourite sets will appear here</span>
                        </div>
                    }
                </div>
                
            </div>

            <div style={{position:'relative',margin:'auto',marginLeft:'15%',minWidth:'960px',marginTop:'5%'}}>
                            <br></br><br></br><br></br><br></br>
                            <p className='new-content-text'style={{fontSize:'17px'}}> <b><i>How to cite this work : </i></b> MorphoNet can be used to visualize interactively and to generate pictures and movies for a variety of data types. When using MorphoNet and the underlying data in a publication, please cite both the MorphoNet paper published in <a style={{textDecoration:'none',color:'#7678ff'}} href="https://www.nature.com/articles/s41467-019-10668-1" target="_blank"><img style={{verticalAlign:'middle'}} alt="nature" type="image" width="125px"  src="/images/naturecomm.svg" ></img></a> and the source of the 3D (+t) data visualized, which can be found in the metadata of each dataset. </p>
                            <br></br>
                        </div>
        </div>
                    </div>
                :
                <div style={{float:'left',position:'relative',margin:'auto',marginTop:'5%'}}>
					<h1 style={{fontSize:'19px',textAlign:'center',marginLeft:'5px',marginRight:'5px'}}> A novel concept of web-based morphodynamic browser. </h1>
                    <br></br>
                    <h1 style={{fontSize:'17px',textAlign:'center',marginLeft:'10px',marginRight:'10px'}}> MorphoNet is an <b>interactive anatomical browser for 3D, 3D+t segmented datasets</b>. It provides a  <b>comprehensive</b> palette of <b>interaction tools</b> to explore the structure, dynamics and variability of <b>biological shapes, from fossils to developing embryos</b>. Some datasets are also linked to <b>single-cell gene expression data</b>. MorphoNet handles both natural or simulated <b>morphological data</b> and fills a gap which has until now limited the <b>quantitative understanding of morphodynamics and its genetic underpinnings</b> by contributing to the creation of morphological atlases. </h1>
                    <br></br><br></br>
                    <h1 style={{fontSize:'21x',color:'#44D4FF'}} className='sub-titles-text'> Public datasets examples</h1>
                    {   this.state.sets.length > 0 ?
                        <List
                        itemLayout="horizontal"
                        dataSource={this.state.sets}
                        style={{outline:' 0.5px solid #44D4FF',outlineOffset:'2px',marginLeft:'10px',marginRight:'10px'}}
                        renderItem={item => (
                        <div>
                        {
                            <List.Item >
                            <img onClick={() => { 
                                    if (isOnStandalone()) {this.props.openUnityFromAway();this.props.unityLoadFunc(item);}}} alt="Dataset " width='50' src={"GIF/DATASET_"+item.id+"-50.gif"} style={{mixBlendMode:'multiply'}}></img>
                            <List.Item.Meta
                             title={
                                <span style={{paddingLeft:'5px',fontSize:'17px'}} className='new-content-text'>{item.name}</span>
                          }
                            />
                        </List.Item>
                        }

                        </div>
                        )}
                    />
                        :
                        <div>
                        <br></br>
                        <span style={{fontSize:'23px'}} > Your favourite sets will appear here</span>
                        </div>
                    }
                                        <NavLink exact to={{pathname:`/dataset`}}><span style={{float:'right',fontSize:'17px',color:'#44D4FF',marginTop:'1%'}}> View all datasets</span></NavLink>
                </div>
                 }
            </MobileView>
            <BrowserView>
            {
                (!isTablet && !isIOS) &&
                <div>
                    <div style={{marginTop: '80px',marginLeft:"10%"}}>
                        <div style={{float: 'left',width:"49%",height:"15vh", paddingTop:"2%"}}>
                            <h1 className='main-title'> MorphoNet </h1>
                            <br></br>
                            <h1 style={{fontSize: '27px', fontWeight: "bold", fontFamily: 'Arial'}}> A novel concept of 3D interactive morphogenesis</h1>
                        </div>
                        <div style={{
                            display:"inline-block",
                            float: 'right',
                            height:"15vh",
                            marginRight:"10%",
                            width: "15%",
                            textAlign: "center",
                        }} onClick={() => {
                            this.props.SetRedirectedUrl("/downloads");
                        }}>
                            <div style={{
                                outline: "solid",
                                padding:"10px",
                                borderRadius: "25px"
                            }}>
                            <h1 className='main-title'> Download </h1>
                            <div style={{marginTop:"1%"}}>
                            <Avatar shape="square" style={{
                                width: '48px',
                                height: '48px'
                            }} src={"images/refonte/logo_linux2.png"}></Avatar>
                            <Avatar shape="square" style={{
                                width: '48px',
                                height: '48px'
                            }} src={"images/refonte/m1.png"}></Avatar>
                            <Avatar shape="square" style={{
                                width: '48px',
                                height: '48px'
                            }} src={"images/refonte/logo_mac2.png"}></Avatar>
                            <Avatar shape="square" style={{
                                width: '48px',
                                height: '48px'
                            }} src={"images/refonte/logo_windows.png"}></Avatar>
                            </div></div>
                        </div>
                    </div>
                    <br></br>
                    <div style={{marginTop: '100px', marginLeft: "10%"}}>
                        <div style={{float: 'left', width: "30%",height:"15vh",}}>
                            <img alt="MorphoNet summary scheme"
                                 style={{
                                     width: "100%",
                                     maxWidth: "680px",
                                     maxHeight: "680px",
                                 }} width={30} src="/images/refonte/MorphoNetFigureWebPage.png"></img>
                        </div>
                        <div style={{
                            display: "inline-block",
                            float: 'left',
                            marginLeft: "2%",
                            width: "30%",
                            height:"15vh",
                            maxWidth: "680px",
                            maxHeight:"680px",
                            textAlign: "center",
                        }}>
                            <p style={{
                                textAlign: "center",
                                verticalAlign: "center",
                                fontFamily: "Arial,serif",
                                fontSize: "24px",
                                textJustify:"auto",
                                overflow:"hidden"
                            }}> MorphoNet is an interactive viewer dedicated to 3D and 3D+t datasets made with intensity
                                images and/or segmented data. The standalone application packages state-of the art
                                segmentation algorithms in an intuitive interface and provides a large array of image
                                processing plugins for efficient 3D segmented data curation. The online platform
                                facilitates sharing and augmenting datasets with additional quantitative or qualitative
                                information, and allows linking datasets to single-cell gene expression databases. </p>

                        </div>
                        <div style={{
                            display: "inline-block",
                            float: 'left',
                            marginLeft: "2%",
                            width: "30%",
                            height:"15vh",
                            textAlign: "center",
                        }}>
                            <h1 style={{fontSize: '32px'}}
                                className='sub-titles-text'>Public datasets examples</h1>
                            <br></br>
                            {this.state.public_sets.length > 0 &&
                                <Row gutter={[16,16]} style={{outline:' 0.8px solid #44D4FF',outlineOffset:'2px'}}>
                                    { this.state.public_sets.map((item,i) => <Col span={8}>
                                        <Tooltip placement="bottom" title={
                                            <span>{"Load this in the 3D viewer"}</span>}>
                                            <img onClick={() => {
                                                this.props.sendTracking(item.id, "Ipad,Index,Dataset,visualize");
                                                this.props.openUnityFromAway();
                                                this.props.unityLoadFunc(item);
                                            }} alt="dataset gif" width='80'
                                                 src={"GIF/DATASET_" + item.id + "-100.gif"} style={{
                                                cursor: this.state.unity_loaded ? 'pointer' : '',
                                                mixBlendMode: 'multiply'
                                            }}></img></Tooltip>
                                        </Col>)
                                    }
                                    
                                </Row>

                            }
                    </div>
                    </div>
                    <br></br>
                    {this.state.token !== "" && this.state.token != null &&
                        <div style={{marginTop: '25%'}}>
                                <div ref={this.datasetlist} style={{
                                    marginTop: '2%',
                                    width: '80%',
                                    float: 'left',
                                    marginLeft: '11%',
                                    marginRight: '10px'
                                }}>
                                    <h1 style={{fontSize: '32px'}}
                                        className='sub-titles-text'> Your favourite datasets</h1>
                                    <NavLink exact to={{pathname: `/dataset`}}><span
                                        style={{float: 'right', fontSize: '17px', color: '#44D4FF', marginTop: '1%'}}> View all datasets</span></NavLink>
                                    {this.state.sets.length > 0 ?
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={this.state.sets}
                                            style={{
                                                outline: ' 0.5px solid #44D4FF',
                                                outlineOffset: '2px',
                                                paddingLeft: '10px',
                                                minWidth: '600px'
                                            }}
                                            renderItem={item => (
                                                <div>
                                                    {
                                                        <List.Item>
                                                                <Tooltip placement="bottom"
                                                                         title={this.state.unity_loaded || this.getUserSettingsValue("standalone_start") === "true" ?
                                                                             <span>Load this dataset in the visualizer</span> :
                                                                             <span>Viewer is still loading ...</span>}>
                                                                    <img alt={"start load"} onError={(e) => {
                                                                        e.target.src = "/images/refonte/load_small.png";
                                                                        e.target.width = e.target.src === "/GIF/DATASET_" + item.id + "-50.gif" ? '50' : "50";
                                                                        e.target.onerror = null;
                                                                    }} onContextMenu={(evt) => {
                                                                        evt.preventDefault();
                                                                        localStorage.setItem('unityProps', encryptString(JSON.stringify(item)));
                                                                        window.open(window.location.href, '_blank');
                                                                        return false;
                                                                    }} onClick={() => {
                                                                        if (this.state.unity_loaded || (isOnStandalone() || this.getUserSettingsValue("standalone_start") === "true")) {/*this.props.unityReset();*/
                                                                            this.props.sendTracking(item.id, "Web,Dataset,visualize");
                                                                            this.props.openUnityFromAway();
                                                                            this.props.unityLoadFunc(item);
                                                                        }
                                                                    }} style={{
                                                                        cursor: 'pointer',
                                                                        marginLeft: '10px',
                                                                        marginRight: '10px'
                                                                    }} width={50}
                                                                         src={"/GIF/DATASET_" + item.id + "-50.gif"}></img>
                                                                </Tooltip>
                                                            <List.Item.Meta
                                                                title={
                                                                    <Tooltip placement="bottom"
                                                                             title={<span>See dataset details</span>}><span
                                                                        style={{paddingLeft: '5px'}} onClick={() => {
                                                                        this.props.SetRedirectedUrl("/dataset/" + item.id + "/");
                                                                        this.props.sendTracking(item.id, "Web,Index,Dataset,click");
                                                                    }}
                                                                        className='new-content-text'>{item.name}</span></Tooltip>
                                                                }
                                                            />
                                                        </List.Item>
                                                    }
    
                                                </div>
                                            )}
                                        />
                                        :
                                        <div>
                                            <br></br>
                                            <span style={{fontSize: '23px'}}> Your favourite sets will appear here</span>
                                        </div>
                                    }
                                </div>
                        </div>
                    }

                </div>
            }
            </BrowserView>
            </div>
        );
    }
}

const mapStateTopProps = (state) => {
    return {
        dataset: state.dataset,
    }
}
//bind logout to global logout system
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl: (url) => dispatch(SetRedirectedUrl(url)),
    }
}

export default withRouter(connect(mapStateTopProps, mapDispatchToProps)(WelcomeReworked));