import {connect} from "react-redux";
import {GetSetInUrl} from "../utils/tools";
import React from "react";
import {AutoComplete, Button, Card, Form, Input, List, Row} from "antd";
import {ChooseGroup, PostNewStudy} from "../utils/api_callbacks";
import {getUserID} from "../utils/CookieManagement";
import {SharingGroupInstance} from "../Sharings/sharingGroupInstanceContainer";
import {NavLink} from "react-router-dom";
import {SetRedirectedUrl} from "../store/reducers/userSlice";

const {Option} = AutoComplete;

export function ChooseSetFromUrl(url) {
    return GetSetInUrl(url);
}

class UpdateGroup extends React.Component {


    state = {
        group_id: -1,


    }

    componentDidMount() {
        ChooseGroup(ChooseSetFromUrl(window.location.pathname));

    }

    isAdminOrCreator(group) {
        const user_id = getUserID();
        return group.id_owner === user_id || this.props.user_is_admin;
    }



    render() {

        if (this.props.user.current_choosen_group === -1) {
            return <div></div>
        }
        const groups = Object.values(this.props.user.group_list).filter(group => group.id === this.props.user.current_choosen_group);
        if (groups.length < 1) {
            return <div></div>
        }
        const group = groups[0];
        const user_list = this.props.user_contacts;
        const studies = this.props.user.studies_by_group[group.id.toString()];
        return (
            <div style={{marginLeft:"10%"}}>

                <h1 style={{textAlignLast:"center",color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>Update group : {group.name}</h1>
                <h1 style={{textAlignLast:"center",color:'#292e37',fontSize:'21px'}}>Created the {group.date.replace('T', ' ').replace('Z', ' ')}</h1>
                <Form>
                    <div style={{textAlign:"center",marginTop:"2%"}}><h3 style={{color: '#44D4FF', fontSize: '20px'}}> Studies </h3>
                    <div >  <Row gutter={16}>{studies !== undefined && studies.map(study => {
                        return <Card title={<NavLink to={'/group/'+group.id+'/study/'+study.id}>

                            <span style={{color: '#44D4FF', fontSize: '16px'}} >{study.name}  </span></NavLink>} style={{display:"inline-grid",width:"300px",marginLeft:"30px"}} key={"study_"+study.id}>

                            {study.description !== "" ?
                                <p style={{fontSize:'14px'}}>{study.description}</p>:<p><br></br></p>}
                        </Card>
                    })}</Row></div>
                        <br></br>
                        <Button type="primary" onClick={() => {this.props.SetRedirectedUrl("/group/"+group.id+"/study/create/")}}
                                style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px',marginTop:"1%"}}>
                            Create study
                        </Button>


                    </div>

                    {
                        user_list !== undefined && user_list.length > 0 &&
                        <div style={{marginTop:"3%"}}>
                            <Form.Item>
                                <h3 style={{color: '#44D4FF', fontSize: '20px'}}>Invite an user </h3>
                                <List
                                    itemLayout="horizontal"
                                    style={{width: '100%'}}
                                    disabled={!this.isAdminOrCreator(group)}
                                    dataSource={user_list}
                                    renderItem={item => (
                                        <SharingGroupInstance key={"sharing"+item.id} user={item} group={group}></SharingGroupInstance>
                                    )}

                                />
                            </Form.Item>

                            <Form.Item>
                                <h3 style={{color: '#44D4FF', fontSize: '20px'}}>Invite in group by e-mail </h3>
                                <AutoComplete disabled={!this.isAdminOrCreator(group)} style={{width: 400}}
                                              placeholder="User email"
                                              onChange={value => this.setState({invited_email: value})}>
                                    {
                                        user_list.map(user => <Option key={user.email}
                                                                      value={user.email}>{user.email}</Option>)
                                    }
                                </AutoComplete>
                            </Form.Item></div>
                    }

                </Form>
            </div>
        );
    }
}

const WrappedGroupDetailForm = Form.create({name: 'detail'})(UpdateGroup);


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset,
        user_contacts: state.user.user_contacts,
        user: state.user,
        tags: state.tag,
        user_is_admin: state.user.is_staff,
        sharings: state.sharing.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WrappedGroupDetailForm);